import React from 'react'
import {
  Container,
  Link,
  Section,
  CommonBanner,
  Seo,
} from '@components'
import { PageLayout } from '../../components/site/layout/page-layout'
import { ApproovLogo } from '../../components/site/approov'
import { OfferContent } from '../../components/site/offer'

const TrialConfirmationPage = ({ location }) => {
  const offer = 'trial-signup'

  return (
    <PageLayout pathname={location.pathname}>
      <CommonBanner title="Approov Free Trial Confirmation" />

      <OfferContent offer={offer}>
        <Section>
          <Container className="pt-8 container-lg">
            <div className="flex items-center">
              <div className="flex w-4/12">
                <ApproovLogo className="w-full h-full" />
              </div>
              <div className="w-9/12 ml-16">
                <p>Your Approov trial request has been confirmed.</p>
                <p>
                  You should shortly receive two emails from Approov. One
                  includes instructions on how to get started, and the other
                  includes credentials needed to complete your initial setup.
                </p>
                <p>
                  If you do not receive these emails or have any other
                  questions, please let us know at the{' '}
                  <Link href="https://approov.io/info/contact">contact us</Link> page, and we will do
                  our best to get you up and running.
                </p>
              </div>
            </div>
          </Container>
        </Section>
      </OfferContent>
    </PageLayout>
  )
}

export default TrialConfirmationPage
